<template>
  <div class="projectXinFeng">
    <navHeader :index="3" :idx="parseInt(this.$route.query.type) + 11"></navHeader>

    <div class="top">
      <div class="topInner">
        <!-- <div class="titleName">鑫锋村</div> -->
        <div class="titleName"><i class="iconfont icon-xinfengcun"></i></div>
        <div class="titleText">
          罗星街道鑫锋村作为省级美丽乡村精品特色村、省级善治示范村、省级卫生村、省级文化示范村，一直走在乡村建设的前沿，更是入选了浙江省第二批未来乡村创建名单！此次未来乡村建设也是处处融入了数字化，抓住数字化转型风口，结合生态化、人本化，让未来乡村叠加在美丽田园、美丽乡村、美丽环境、美丽经济之上！罗星街道鑫锋村作为省级美丽乡村精品特色村、省级善治示范村、省级卫生村、省级文化示范村，一直走在乡村建设的前沿，更是入选了浙江省第二批未来乡村创建名单！此次未来乡村建设也是处处融入了数字化，抓住数字化转型风口，结合生态化、人本化，让未来乡村叠加在美丽田园、美丽乡村、美丽环境、美丽经济之上！
        </div>
      </div>
    </div>
    <div class="environmentPic">
      <img class="" :src="require(`@/assets/images/project/xf1.png`)" alt="" />
    </div>
    <div class="mainContent">
      <div class="contentArea">
        <div class="textStyle1">
          在此基础上，为了更好地全面展示鑫锋村农业数字信息和智能农业管理，罗星街道鑫锋村与浙江尚阳网络信息服务要有限公司合作，借助尚阳公司在智慧农场管理和展示，农业废弃物回收利用，以及互联网大数据分析等方面累积的数年经验进行农业数字化展示。
        </div>
        <div class="textStyle2">
          协助相关部门做到全面、有效、及时掌握整个鑫锋村农业规模、生产状态、农业发展趋势，及时把握发展方向，以便快速应对挑战、抓住机遇！此外，对鑫锋村现代新农村的智慧和农业种植的便捷进行了良好的宣传展示。
        </div>
        <img class="midPic" :src="require(`@/assets/images/project/xf2.png`)" alt="" />
        <div class="textStyle3">
          进一步提高农业气象灾害监测水平，为农业生产减灾增产提供坚强的保障。实时握土壤和水质等环境状况，为种植生产提供参考数据，保障农业生产的增产增收。同时能够帮助管理部门更好了解并指导生产和劳作，提升管理效率，降低劳作成本。
        </div>
        <div class="title">项目实践</div>
        <div class="videoWrapper">
          <iframe id="fram_box" frameborder="0" :src="videoArr[0]" allowFullScreen="true"></iframe>
          <iframe id="fram_box" frameborder="0" :src="videoArr[1]" allowFullScreen="true"></iframe>
          <iframe id="fram_box" frameborder="0" :src="videoArr[2]" allowFullScreen="true"></iframe>
        </div>
        <div class="videoWrapper-m">
          <div
            class="videoWrapperItem"
            v-for="(vItem, vIndex) in videoList"
            :key="vIndex"
            :style="vItem.styleM"
            @click="videoMobileClick(vItem.src)"
          >
            <img class="coverPic" :src="vItem.coverPic" :style="vItem.styleM" alt="" />
            <img
              class="playBtn"
              :src="require(`@/assets/images/commonUse/btnPlay.png`)"
              style="width: 7.46vw; height: 7.46vw"
              alt=""
            />
          </div>
        </div>

        <div class="textStyle3">
          可以看出当前农业数字化在实际建设中的出彩作用，但总的来说我国数字化农业尚在起步阶段。在前期数据获取积累、数据通信建设的基础上，结合农业数据地域性、周期性、时效性、综合性等特点，充分尊重农业自身规律对数字化技术择优而用，厚积薄发，一定在不久的将来可以看见农业数字化的飞速发展，从多维度引领农业现代化。
        </div>
      </div>
    </div>
    <rightsFooter></rightsFooter>
    <videoModal :videoUrl="videoUrlMobile" v-if="isShowPopup" @close="closePopup"></videoModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import navHeader from '@/components/navHeader.vue'
import videoModal from '@/components/videoModal.vue'
import rightsFooter from '@/components/rightsFooter.vue'

export default {
  name: 'ProjectXinFeng',
  components: {
    navHeader,
    videoModal,
    rightsFooter
  },
  data() {
    return {
      videoList: [
        {
          src: 'https://v.qq.com/txp/iframe/player.html?vid=e3510oky4wn',
          coverPic: require(`@/assets/images/product/videoCover1.png`),
          styleM: 'width:33.3vw;height:50vw'
        },
        {
          src: 'https://v.qq.com/txp/iframe/player.html?vid=a3510gpna3q',
          coverPic: require(`@/assets/images/product/videoCover2.png`),
          styleM: 'width:33.3vw;height:50vw'
        },
        {
          src: 'https://v.qq.com/txp/iframe/player.html?vid=y3510b3vb2y',
          coverPic: require(`@/assets/images/product/videoCover3.png`),
          styleM: 'width:33.3vw;height:50vw'
        }
      ],
      videoArr: [
        'https://v.qq.com/txp/iframe/player.html?vid=e3510oky4wn',
        'https://v.qq.com/txp/iframe/player.html?vid=a3510gpna3q',
        'https://v.qq.com/txp/iframe/player.html?vid=y3510b3vb2y'
      ],
      videoUrlMobile: '',
      isShowPopup: false
    }
  },
  computed: {},
  mounted() {},
  methods: {
    videoMobileClick(e) {
      this.videoUrlMobile = e
      this.isShowPopup = true
    },
    openPopup() {
      this.isShowPopup = true
    },
    closePopup() {
      this.isShowPopup = false
    }
  }
}
</script>
<style lang="scss" scoped>
.projectXinFeng {
  // padding-bottom: 100px;
  font-family: 苹方-简;
  font-size: 16px;
  font-weight: normal;
  line-height: 40px;
  letter-spacing: 0px;

  color: #333333;
  .top {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    height: 484px;
    padding-top: 140px;
    .topInner {
      width: 1200px;
      height: 100%;
      .titleName {
        text-align: center;
        font-family: HarmonyOS Sans SC;
        font-size: 40px;
        font-weight: 900;
        line-height: 40px;

        letter-spacing: 0em;

        color: #333333;
        margin-bottom: 64px;
        .iconfont {
          font-size: 40px;
          line-height: 40px;
        }
      }
    }
  }
  @media only screen and (max-width: 1199px) {
    .top {
      height: 100%;
      padding-top: 22.4vw;
      .topInner {
        width: 100%;
        height: 100%;
        .titleName {
          text-align: center;
          font-family: HarmonyOS Sans SC;
          font-size: 40px;
          font-weight: 900;
          line-height: 40px;

          letter-spacing: 0em;

          color: #333333;
          margin-bottom: 20px;
        }
        .titleText {
          color: #333333;
          padding: 0 20px 0 20px;
          font-size: 14px;
          font-weight: 300;
          line-height: 24px;
        }
      }
    }
  }

  .environmentPic {
    width: 100%;
    height: 720px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  @media only screen and (max-width: 1199px) {
    .environmentPic {
      padding: 10.67vw 0 0vw 0;
      width: 100%;
      height: 42.67vw;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  @media only screen and (min-width: 1200px) {
  }

  .mainContent {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    .contentArea {
      width: 1200px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .textStyle1 {
        margin: 80px 0 40px 0;
      }
      .textStyle2 {
        margin: 0px 0 80px 0;
      }
      .midPic {
        width: 844px;
        height: 478px;
      }
      .textStyle3 {
        margin: 80px 0 20px 0;
      }
      .title {
        font-family: HarmonyOS Sans SC;
        font-size: 40px;
        font-weight: 900;

        letter-spacing: 0em;

        color: #333333;
        margin-bottom: 64px;
      }
      .videoWrapper {
        display: grid;
        grid-gap: 60px;
        grid-template-columns: repeat(3, auto); /* 总共三列，占据剩余空间的比例相等 */
        grid-template-rows: repeat(1, auto); /* 总共两行，自适应高度 */
        #fram_box {
          width: 360px;
          height: 540px;
          background: #000000;
        }
      }
    }
  }
  @media only screen and (max-width: 1199px) {
    .mainContent {
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      width: 100%;
      .contentArea {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .textStyle1 {
          margin: 40px 0 20px 0;
          padding: 0 20px 0 20px;
          line-height: 24px;
          font-size: 14px;
          font-weight: 300;
        }
        .textStyle2 {
          margin: 0px 0 40px 0;
          line-height: 24px;
          font-size: 14px;
          font-weight: 300;
          padding: 0 20px 0 20px;
        }
        .midPic {
          width: 89.3vw;
          height: 50.59vw;
        }
        .textStyle3 {
          margin: 40px 0 40px 0;
          line-height: 24px;
          font-size: 14px;
          font-weight: 300;
          padding: 0 20px 0 20px;
        }
        .title {
          display: none;
        }
        .videoWrapper {
          display: none !important;
          display: grid;
          grid-gap: 0px;
          grid-template-columns: repeat(3, auto); /* 总共三列，占据剩余空间的比例相等 */
          grid-template-rows: repeat(1, auto); /* 总共两行，自适应高度 */
          #fram_box {
            width: 33.3vw;
            height: 50vw;
            background: #000000;
          }
        }

        .videoWrapper-m {
          display: flex;
          .videoWrapperItem {
            width: 33.3vw;
            height: 50vw;
            cursor: pointer;
            position: relative;
            .videoPic {
              width: 33.3vw;
              height: 50vw;
            }
            .playBtn {
              z-index: 1;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate3d(-50%, -50%, 0);
            }
          }
        }
      }
    }
  }
  @media only screen and (min-width: 1200px) {
    .mainContent {
      .contentArea {
        .textStyle1 {
        }
        .textStyle2 {
        }
        .midPic {
        }
        .textStyle3 {
        }
        .title {
        }
        .videoWrapper-m {
          display: none;
        }
      }
    }
  }
}
</style>